export const divisions = (type) => {
  switch (type) {
    case "jewelry":
      return {
        title: "Ювелирные украшения, часы",
        items: [
          {
            name: "Mercury",
            tel: "+74959333393",
            phone: "+7 (495) 933 3393",
          },
          {
            name: "Chopard",
            tel: "+74959333383",
            phone: "+7 (495) 933 3383",
          },
          {
            name: "Graff",
            tel: "+74959333385",
            phone: "+7 (495) 933 3385",
          },
          {
            name: "Patek Philippe",
            tel: "+74959333045",
            phone: "+7 (495) 933 3045",
          },
          {
            name: "Rolex",
            tel: "+74959333370",
            phone: "+7 (495) 933 3370",
          },
        ],
      };

    case "auto":
      return {
        title: "Автомобили",
        items: [
          {
            name: "Bentley",
            tel: "+74959333377",
            phone: "+7 (495) 933 3377",
          },
        ],
      };

    case "live":
      return {
        title: "Товары для\u0a00дома и\u0a00интерьера",
        items: [
          {
            name: "Baccarat",
            tel: "+74959333381",
            phone: "+7 (495) 933 3381",
          },
          {
            name: "Daum",
            tel: "+74959333374",
            phone: "+7 (495) 933 3374",
          },
          {
            name: "Lalique",
            tel: "+74959333374",
            phone: "+7 (495) 933 3374",
          },
        ],
      };

    case "restorants":
      return {
        title: "Рестораны",
        items: [
          {
            name: "Cristal Room Baccarat",
            tel: "+74959333389",
            phone: "+7 (495) 933 3389",
          },
        ],
      };

    case "other-tdm":
      return {
        title: "VIP-шопинг",
        items: [
          {
            name: "",
            tel: "+74959333415",
            phone: "+7 (495) 933 3415",
          },
        ],
      };

    case "other":
    default:
      return {
        title: "VIP-шопинг",
        items: [
          {
            name: "",
            tel: "+74959333048",
            phone: "+7 (495) 933 3048",
          },
        ],
      };
  }
};
